import useToast from '@/composables/helpers/useToast';
import type { ImplicitFlowSuccessResponse } from 'vue3-google-signin';
import type { ApiResponse } from '@/composables/api/types';
import type { AuthResponse } from '@/types/auth';

export default function useSignInWithGoogleSuccessHandler(
  handler: (code: string) => Promise<ApiResponse<AuthResponse>>
) {
  const toast = useToast();

  return async (response: ImplicitFlowSuccessResponse) => {
    if (response?.code) {
      const { error } = await handler(response.code);

      if (error) {
        toast.error(error.data?.message || 'Something went wrong');
      }
    }
  };
}
